import './App.css';
import './variable.css';
import 'react-edit-text/dist/index.css';
import { text } from './components/util/constants.js';

import {BrowserRouter, Route, Switch} from "react-router-dom"
import InterventionIcon from "./images/banicon-interv.png"
import ClientIcon from "./images/banicon-clients.png"
import SiteIcon from "./images/banicon-sites.png"
import Intervention from "./components/bean/Intervention";
import Site from "./components/bean/Site";
import Companies from "./components/lists/Companies"
import Company from "./components/bean/Company";
import Sites from "./components/lists/Sites";
import Interventions from "./components/lists/Interventions";
import PrivateRoute from "./components/util/PrivateRoute";
import AuthPage from "./components/util/AuthPage";
import Devis from "./components/bean/Devis";
import ErrorManager from "./utils/Error";
import IbatApi from "./utils/IbatApi";
import {SnackbarProvider} from "notistack";
import React, {useEffect, useState} from "react";
import SignInForm from "./components/forms/SignInForm";
import {Tooltip} from "@material-ui/core";



function App() {
    const api=new IbatApi()
    const [token, setToken] = useState("")
    function getDefaultStatus(){
        let tokenData = api.getTokenData();
        if (!tokenData){
            return
        }
        // let roles=tokenData.authorities
        // let role=roles[0].authority
        let role=1
        switch (role){
            case "GESTIONNAIRE" :
                return {defaultFilter:""};
            case "TECHNICALMANAGER" :
                return {defaultFilter:""};
            case "BILLMANAGER":
                return {defaultFilter:""}
            default :
                return {defaultFilter:""}
        }
    }

    useEffect(() => {refreshToken()}, [token])

    const refreshToken = () => {
        setToken(api.getToken())
    }

    function handleDeconnexion() {
        api.deconexion()
    }

    return (
            <div className={"myBody"}>

                <div className={"inline"}>
                    <div className={"logo"}>
                        <img/>
                    </div>
                    <nav className={"mainNavbar"}>
                    <ul>
                        <li ><Tooltip title={text.INTERVENTION_TABLE}><a  href={"/"} >   <img alt={"intervention"}  src={InterventionIcon}/></a></Tooltip></li>
                        <li > <Tooltip title={text.CLIENT_TABLE}><a  href={"/companies"}>   <img alt={"companies"} src={ClientIcon}/></a></Tooltip></li>
                        <li > <Tooltip title={text.SITE_TABLE}><a  href={"/sites"}>     <img alt={"sites"} src={SiteIcon}/></a></Tooltip></li>
                        {token!==""&& <li><Tooltip title={text.CONNECT}><div className={"profile"} onClick={handleDeconnexion}>{api.getTokenData()?.username}</div></Tooltip></li>}
                    </ul>
                </nav>
                </div>



                <div className={"mainBody"}>
                    <ErrorManager>
                        <SnackbarProvider maxSnack={3}>
                            <BrowserRouter>
                                <Switch>
                                    <Route path="/login" component={() => <AuthPage refreshToken={refreshToken} />}/>
                                    <Route path="/subscribe" component={SignInForm} />
                                    <PrivateRoute path={"/intervention/:id"} component={Intervention}/>
                                    <PrivateRoute path={"/interventions"} component={Interventions} properties={getDefaultStatus()} />

                                    <PrivateRoute path={"/devis/:id"} component={Devis}/>

                                    <PrivateRoute path={"/company/:id"} component={Company}/>
                                    <PrivateRoute path={"/companies"} component={Companies}/>

                                    <PrivateRoute path={"/site/:id"} component={Site}/>
                                    <PrivateRoute path={"/sites"} component={Sites}/>
                                    <PrivateRoute path={""} component={Interventions} properties={getDefaultStatus()} />
                                </Switch>
                            </BrowserRouter>
                        </SnackbarProvider>
                    </ErrorManager>

                </div>
            </div>
    );
}

export default App;
