import React, {useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import "../../table/interventionsStyle.css"
import Table from "../../table/Table";
import {IconButton} from "@material-ui/core";
import ClientIcon from "../../images/client-add.png";
import Modal from "react-responsive-modal";
import ClientForm from "../forms/ClientForm";
import {text} from "../util/constants";

const columns = [
  {id: "id", isFiltrable: true, label: text.NUMBER, type: "linkText", isChecked: true},
  {id: 'mail', isFiltrable: true, label: text.MAIL, type: "text", isChecked: true},
  {id: "name", isFiltrable: true, label: text.NAME, type: "text", isChecked: true},
  {id: "phone", isFiltrable: true, label: text.NUMBER, type: "text", isChecked: true},
  {id: "address", isFiltrable: true, label: text.ADDRESS, type: "text", isChecked: true},
  {id: "cp", isFiltrable: true, label: text.ZIPCODE, type: "text", isChecked: true},
  {id: "ville", isFiltrable: true, label: text.TOWN, type: "text", isChecked: true},
]

export default function Companies() {
  const [state, setState] = useState({
    columns: columns,
    companiesList: [],
    pageSize: 20,
    pageNumber: 0,
    count: 0,
    filters: {
      id: "",
      mail: "",
      name: "",
      phone: "",
      address: "",
      cp: "",
      ville: "",

    }
  })
  const [typing, setTyping] = useState(false)

  let api = new IbatApi();
  useEffect(() => {
    getCompanies()
  }, [typing, state.pageNumber, state.pageSize])


  const handleColumns = (event) => {
    let columns = state.columns
    let filter = {...state.filters}
    let find = columns.find(col => col.id === event.target.name);
    find.isChecked = event.target.checked
    if (find.isFiltrable) {
      filter[find.id] = ""
    }
    setState(state => ({...state, columns: columns, filters: filter}))
  }
  function getCompanies() {
    const result = api.getCompanies({
      ...state.filters,
      pageNumber: state.pageNumber,
      pageSize: state.pageSize
    })
    result.then(res => {
      setState(state => ({...state, companiesList: res.content, count: res.totalElements}))
    })
  }
  function handleFilter(e) {
    let value = e.target.value
    let name = e.target.name
    setState(state => ({...state, filters: {...state.filters, [name]: value}}));
  }
  function handleChangePage(newPage) {
    setState(state => ({...state, pageNumber: newPage}))
  }
  function handleChangeRowsPerPage(event) {
    setState(state => ({...state, pageSize: event.target.value, pageNumber: 0}))
  }

  return (

    <Table
      onHandleFilter={handleFilter}
      onHandleChangePage={handleChangePage}
      onHandleChangeRowsPerPage={handleChangeRowsPerPage}
      onGetData={getCompanies}
      onHandleColumns={handleColumns}
      rowCount={state.count}
      filter={state.filters}
      onEndTyping={setTyping}
      pageNumber={state.pageNumber}
      pageSize={state.pageSize}
      columns={state.columns}
      list={state.companiesList}
      tabName={"company"}
    >
    </Table>
  )
}
