import {useSnackbar} from "notistack";
import React, {useState} from "react";
import IbatApi from "../utils/IbatApi";
import constants, {text} from "../components/util/constants";
import BillIcon from "../images/new-devis.png";
import {IconButton} from "@material-ui/core";

export default function EditEstimate({id,update}) {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [estimate, setEstimate] = useState("");

    let api = new IbatApi();

    function handleTime(e) {
        setEstimate(e.target.value);
    }

    function handleSave() {
        api.setEstimate(id, estimate)
            .then(c => update())
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED, {variant: "success"}))
            .catch(e => enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"}))
    }


    if (!api.hasRole(constants.PERMISSION_BILL)) {
        return <h3>{text.NO_RIGHTS_PROMPT}</h3>
    }
    return (<div className={"editIntervention"}>
            <div className={"img-container"}>
                <img src={BillIcon}/>
            </div>
            <div className={"container"}>
                <h2>{text.ESTIMATE_NUMBER}</h2>
                <input type={"text"} placeholder={text.ESTIMATE_NUMBER} value={estimate} onChange={handleTime}/>
                <IconButton className={"validation-button"} onClick={handleSave}>Enregistrer</IconButton>
            </div>

        </div>

    )
}