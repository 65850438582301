import React, {Fragment, useState} from "react"
import IconButton from "@material-ui/core/IconButton";
import OpenFormIcon from "../../images/new-interv.png"
import SaveIcon from "../../images/button-create.png";
import CancelIcon from "../../images/button-cancel.png";
import ValidateFormIcon from "../../images/new-interv-ok.png"
import "../style/InterventionFormlStyle.css"
import IbatApi from "../../utils/IbatApi";
import "../style/style.css"
import CreateDevis from "./CreateDevis";
import Modal from "react-responsive-modal";
import constants from "../util/constants";
import Tooltip from "@material-ui/core/Tooltip";
import {useSnackbar} from "notistack";
import { text } from "../util/constants";
import {isSpecialIntervention} from "../../helpers/interventionManager";

export default function InterventionForm({onSaveSucceed}) {
    const default_state={
        devis: {clientIdBCommande: ""},
        intervention:{}
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [state, setState] = useState(default_state)
    const [show, setShow] = useState(false)
    const [isSavable, setIsSavable] = useState(false)
    const api = new IbatApi()




    function handleSave() {
        console.log(state)
        let intervention= {
            etat: state.intervention !== 0 && state.intervention.team && state.intervention.team.name !== "" ? constants.SCHEDULED : constants.RECEIVED,// todo: ajouter environement variable
            idSecteur: state.intervention.secteur?state.intervention.secteur:null,
            idTypeIntervention: state.intervention.type,
            teamId: state.intervention.team?state.intervention.team.id:null,
            dateIntervention: state.intervention.intervention?new Date(state.intervention.intervention).getTime():0,
            jobTypeId: state.intervention.jobTypeId,
            estimateDuration:state.intervention.estimateDuration?state.intervention.estimateDuration:constants.DEFAULT_DURATION,
            dateEndIntervention: new Date(new Date(state.intervention.intervention).getTime()+state.intervention.dateEndIntervention).getTime()
        }

        let myUserId = api.getMyUserId();
        let devisApi = {
            ...state.devis,
            acompte: state.acompte,
            clientIdBCommande: state.clientIdBCommande,
            montant: state.montant,
            clientId: state.clientId,
            myUserId: myUserId ,
            comments: state.comments,
            dateReception: new Date(state.reception).getTime(),
            documents: state.documents?state.documents:[],
            devisData:state.devisData?state.devisData:[],
            supplyMachinery:state.supplyMachinery?state.supplyMachinery:[],
            supplyDocument:state.supplyDocument?state.supplyDocument:[],
            supplyAdminDocument:state.supplyAdminDocument?state.supplyAdminDocument:[],
            intervention: {...intervention}
        }
        api.creatIntervention(state.site.id, devisApi).then(resp => {
            setState(default_state);
            setIsSavable(false)
            setShow(false)
            onSaveSucceed()
            window.open(`/devis/${resp.id}`, "_blank")
        }) .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))
    }

    function handleCancel() {
        setState(default_state)
        setShow(false)
    }

    function handleSavable(data) {
        if(!isSpecialIntervention(data.intervention.type)){
            setIsSavable(data.reception && data.clientId &&  data.siteId && data.intervention.type && data.intervention.secteur!==null)
            return
        }
        setIsSavable(  data.siteId
            && data.intervention.type
            && data.intervention.secteur
            && data.intervention.team
            && data.intervention.intervention
            && data.intervention.estimateDuration!==null
        )

    }

    function handleData(data) {
        setState(data)
        handleSavable(data)
    }

    return (
        <Fragment>
            <Tooltip title={text.TOOLTIP_CREATE_COMMAND}>
                <IconButton disabled={!api.hasRole(constants.PERMISSION_AM) } onClick={() => {setShow(true)}}>
                    <img src={OpenFormIcon}/>
                </IconButton>
            </Tooltip>

            <Modal open={api.hasRole(constants.PERMISSION_AM) && show} onClose={()=>setShow(false)}>
                <div className={"commande-form"}>
                    <div className={"commande-head"}>
                        <h2>{text.H2_CREATION_COMMAND}</h2>
                         </div>
                    <CreateDevis onChange={handleData}/>
                    <IconButton onClick={isSavable?() => {handleSave();setShow(false)}:() => {setShow(false)}}>
                        <div>{isSavable?<IconButton className={"validation-button"}>{text.CREATE}</IconButton>:<IconButton className={"cancel-button"}>{text.CANCEL}</IconButton>} </div>
                    </IconButton>

                </div>
            </Modal>
        </Fragment>




            )
}