import React, {useEffect, useState} from "react";
import {IconButton} from "@material-ui/core";
import {text} from "../components/util/constants";
import "../components/style/ClientStyle.css";
import PieChart, {
  CommonSeriesSettings,
  Connector,
  Export,
  Font,
  Format,
  Label,
  Legend,
  Series,
  Title,
  Tooltip
} from "devextreme-react/pie-chart";
import {
  Chart,
  CommonAxisSettings,
  Export as ChartExport,
  Legend as ChartLegend,
  Series as ChartSeries,
  Font as ChartFont,
  Label as ChartLabel,
  CommonSeriesSettings as ChartCommonSeriesSettings,
  Size,
  Title as ChartTitle
} from "devextreme-react/chart";

import IbatApi from "../utils/IbatApi";

export function Statistiques({id}) {
  const api = new IbatApi();
  const graphColors = [
    'var(--graph-color-1)',
    'var(--graph-color-2)',
    'var(--graph-color-3)',
    'var(--graph-color-4)'];

  const [state, setState] = useState({})
  const [doc, setDoc] = useState(false)

  const [stats, setStats] = useState(false)
  const [dataStats, setdataStats] = useState(false)

  const[insuranceStats, setInsuranceStats] = useState(false)

  const [delay, setDelay] = useState(false)
  const [delayStats, setdelayStats] = useState(false)
  const [interval, setInterval] = useState({start: "", end: ""})
  const [graphSize, setGraphSize] = useState(false)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getCompany()
  }, [])


  function getCompany() {
    let company = id ?? -1
    if (id) {
      api.getCompanyById(id).then(d => {
        setState(state => ({...state, ...d}))
        api.getFile(d.logo).then(z => setDoc(z))
      })
    }
  }
  function refreshStats(){
    setLoading(true)
    let company = id ?? -1
    api.getStats(company, {start: interval?.start, end: interval?.end}).then(response => {
      setLoading(false)
      let stats = response.stats
      let insurances = Object.keys(stats.insuranceStat)
      Object.keys(stats.canceledInsuranceStat).forEach(k=>{
        if(!insurances.includes(k)){
          insurances.push(k)
        }
      })
      let statsParsed = {
        total: stats.total,

        totalCanceled: stats.canceled,
        totalWithoutCancel: stats.total - stats.canceled,
        totalPreparing: stats.preparing,
        totalRunning: stats.running,
        totalAchieved: stats.achieved,

        totalSAV: stats.totalSAV,

        insurancesList: insurances.sort(function(a, b) {
          return a < b ? -1 : 1 ;
        }),
        insuranceStat: stats.insuranceStat,
        canceledInsuranceStat: stats.canceledInsuranceStat
      }

      let insuranceStatsList = []
      insurances.forEach(insurance=>{
        let data = {
          insurance:insurance.replace("zzz", ""),
          achieved: parseFloat(roundValue((stats.insuranceStat[insurance]??0) / ((stats.insuranceStat[insurance]??0) + (stats.canceledInsuranceStat[insurance]??0)) * 100)),
          canceled: parseFloat(roundValue((stats.canceledInsuranceStat[insurance]??0) / ((stats.insuranceStat[insurance]??0) + (stats.canceledInsuranceStat[insurance]??0)) * 100)),
        }
        insuranceStatsList.push(data)
      })
      setInsuranceStats(insuranceStatsList)


      setStats(statsParsed)
      setdataStats([
        {key: text.ACHIEVED, val: stats.achieved},
        // {key: text.REPORTED, val: d.reported},
        {key: text.RUNNING, val: stats.running},
        {key: text.CANCELED, val: stats.canceled},
        {key: text.PREPARING, val: stats.preparing}
      ])




      let delay = response.delay
      let cd = mapDelay(delay.calendarDay)
      let bd = mapDelay(delay.businessDay)

      let size = delay.calendarDay.length

      let delayValue = {
        j0_c: cd[0],
        j0_b: bd[0],

        j1_c: cd[1],
        j1_b: bd[1],
        j1_c_c: cd[0] + cd[1],
        j1_b_c: bd[0] + bd[1],

        j2_c: cd[2],
        j2_b: bd[2],
        j2_c_c: cd[0] + cd[1] + cd[2],
        j2_b_c: bd[0] + bd[1] + bd[2],

        j3_c: cd[3],
        j3_b: bd[3],
        j3_c_c: cd[0] + cd[1] + cd[2] + cd[3],
        j3_b_c: bd[0] + bd[1] + bd[2] + bd[3],

        j4_c: size - (cd[0] + cd[1] + cd[2] + cd[3]),
        j4_b: size - (bd[0] + bd[1] + bd[2] + bd[3]),
        j4_c_c: size,
        j4_b_c: size,
      }
      setDelay(delayValue)

      let data = [];
      let businessCumul = 0
      let calendarCumul = 0
      for (let i = 0; i <= 30; i++) {
        let indice = 0

        businessCumul += ((bd[i] ?? 0) / size * 100)
        calendarCumul += ((cd[i] ?? 0) / size * 100)

        if (i < 30) {
          indice = i.toString()
        } else {
          indice = "30+"
        }

        let value = {
          dayN: indice,
          businessDay: ((bd[i] ?? 0) / size * 100),
          calendarDay: ((cd[i] ?? 0) / size * 100),
          businessCumul: businessCumul,
          calendarCumul: calendarCumul
        }
        data.push(value)
      }
      setdelayStats(data)
    })
  }

  function handleStart({target}) {
    setInterval({start: target.value, end: interval.end})
  }
  function handleEnd({target}) {
    setInterval({start: interval.start, end: target.value})
  }

  function mapDelay(delayList) {
    let delay = delayList.sort(function (a, b) {
      return a - b;
    }).reduce(function (acc, curr) {
      if (curr >= 30) {
        curr = 30
      }
      return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
    }, {})
    if(!delay[0]){
      delay[0]=0
    }
    if(!delay[1]){
      delay[1]=0
    }
    if(!delay[2]){
      delay[2]=0
    }
    if(!delay[3]){
      delay[3]=0
    }
    return delay
  }
  function roundValue(value, digit) {
    let r = (Math.round((value) * 10000) / 10000).toFixed(digit ?? 2)
    if (value.toString() !== "NaN"){
      return r
    }
    return "--"
  }

  function customizeTooltip(arg) {
    return {
      text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`,
    };
  }
  function customizeTooltipForDelay(arg) {
    return {
      text: `${roundValue(arg.valueText)}% soit ${roundValue(arg.valueText / 100 * delay.j4_c_c, 0)} interventions`,
    };
  }
  function customizeTooltipForInsurance(arg) {
    let i = arg.argument === "Non précisé" ? "zzz"+arg.argument : arg.argument
    let total = ((stats.insuranceStat[i]??0) + (stats.canceledInsuranceStat[i]??0))
    return {
      text: `${arg.argument} :\n${roundValue(arg.valueText)}% soit ${roundValue(((arg.valueText??0) / 100) * total, 0)} interventions`,
    };
  }

  return (
    <div className={"client-page"}>
      <section>
          <section>
            {id &&
            <div className={"client-head"}>
              <img alt={"serviceProviderLogo"} src={doc?.url}/>
              <div title={"Nom : "}><a href={"/client/" + state.id}> {state.name}</a></div>
              <div title={"Numéro de Téléphone : "}>{state.phone}</div>
              <div title={"Mail : "}> {state.mail}</div>
            </div>
            }
          </section>
          <div className={"client-body"}>
            <div className={"planningHead"} id={"clientPlanningHead"}>
              <div className={"form"}>
                <input type={"date"} id={"dateStart"} name={"dateStart"} onChange={handleStart}/>
                <input type={"date"} id={"dateEnd"} name={"dateEnd"} onChange={handleEnd}/>
                <div>
                  <IconButton className={"refresh-button"} onClick={refreshStats}> {text.REFRESH} </IconButton>
                </div>
              </div>
            </div>
            {loading && <h2>Analyse des données en cours...</h2>}
            {stats && <div className={"table_graph"}>
              <div className={"PartTabGraph"}>
                <table className={"tableMain"}>
                  <tr>
                    <td>
                      <table className={"statsTable"}>
                        <tr>
                          <th className={"header"} colSpan="4">Volumétrie-Missions</th>
                        </tr>
                        <tr>
                          <th/>
                          <th>{text.ORDERED_COMMAND}</th>
                        </tr>
                        <tbody>
                        <tr>
                          <th>{text.TOTAL}</th>
                          <td>
                            <td className={"rawNumber"}>{stats.total}</td>
                            <td className={"separator"}>&nbsp;|&nbsp;</td>
                            <td className={"percentRate"}>{roundValue(stats.total / stats.total * 100)}%</td>
                          </td>
                        </tr>
                        <tr>
                          <th>{text.CANCELED}</th>
                          <td>
                            <td className={"rawNumber"}>{stats.totalCanceled}</td>
                            <td className={"separator"}>&nbsp;|&nbsp;</td>
                            <td className={"percentRate"}>{roundValue(stats.totalCanceled / stats.total * 100)}%</td>
                          </td>
                        </tr>
                        <tr>
                          <th>{text.TOTAL} hors {text.CANCELED}</th>
                          <td>
                            <td className={"rawNumber"}>{stats.totalWithoutCancel}</td>
                            <td className={"separator"}>&nbsp;|&nbsp;</td>
                            <td className={"percentRate"}>{roundValue(stats.totalWithoutCancel / stats.total * 100)}%</td>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr className={"noBackground"}>
                    <td>
                      <table className={"statsTable"}>
                        <tbody>
                        <tr>
                          <th className={"header"} colSpan="4">Concernant le total hors annulation</th>
                        </tr>
                        <tr>
                          <th/>
                          <th>{text.ORDERED_COMMAND}</th>
                        </tr>
                        <tr>
                          <th>{text.TOTAL}</th>
                          <td>
                            <td className={"rawNumber"}>{stats.totalWithoutCancel}</td>
                            <td className={"separator"}>&nbsp;|&nbsp;</td>
                            <td className={"percentRate"}>{roundValue(stats.totalWithoutCancel / stats.totalWithoutCancel * 100)}%</td>
                          </td>
                        </tr>
                        <tr>
                          <th>{text.PREPARING}</th>
                          <td>
                            <td className={"rawNumber"}>{stats.totalPreparing}</td>
                            <td className={"separator"}>&nbsp;|&nbsp;</td>
                            <td className={"percentRate"}>{roundValue(stats.totalPreparing / stats.totalWithoutCancel * 100)}%</td>
                          </td>
                        </tr>
                        <tr>
                          <th>{text.RUNNING}</th>
                          <td>
                            <td className={"rawNumber"}>{stats.totalRunning}</td>
                            <td className={"separator"}>&nbsp;|&nbsp;</td>
                            <td className={"percentRate"}>{roundValue(stats.totalRunning / stats.totalWithoutCancel * 100)}%</td>
                          </td>
                        </tr>
                        <tr>
                          <th>{text.ACHIEVED}</th>
                          <td>
                            <td className={"rawNumber"}>{stats.totalAchieved}</td>
                            <td className={"separator"}>&nbsp;|&nbsp;</td>
                            <td className={"percentRate"}>{roundValue(stats.totalAchieved / stats.totalWithoutCancel * 100)}%</td>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            Missions comprenant un SAV
                          </th>
                          <td>
                            <td className={"rawNumber"}>{stats.totalSAV}</td>
                            <td className={"separator"}>&nbsp;|&nbsp;</td>
                            <td className={"percentRate"}>{roundValue(stats.totalSAV / stats.totalWithoutCancel * 100)}%</td>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
                <PieChart id="chart" type="doughnut" palette={graphColors} dataSource={dataStats} diameter={.5}>
                  {graphSize && <Size width={graphSize}/>}
                  <Title text="Statistiques d'entreprise - Interventions">
                    <Font family="rubik" weight={600} size={20}/>
                  </Title>
                  <CommonSeriesSettings
                      border={{color: "rgba(0,0,0,.3)", centerTemplate: "1", visible: true, width: 1}}/>
                  <Series argumentField="key">
                    <Label visible={true} position="outside"
                           border={{color: "rgba(0,0,0,.5)", width: 1, visible: true}}>
                      <Font family="rubik" weight={600} size={16} color="#FFF"/>
                      <Connector visible={true}/>
                    </Label>
                  </Series>
                  <Export enabled={true}/>
                  <Legend margin={0} horizontalAlignment="left" verticalAlignment="top">
                    <Font family="rubik" weight={600} size={16}/>
                  </Legend>
                  <Tooltip enabled={true} cornerRadius={50} opacity={0.9} shadow={{opacity: 0.2}} zIndex={1000}
                           border={{color: "#000"}} customizeTooltip={customizeTooltip}>
                    <Font family="rubik" weight={600} size={16}/>
                  </Tooltip>
                </PieChart>
              </div>
              <div className={"PartTabGraph onRow"}>
                <div className={"tableWidth"}>
                  <table className={"statsTable statsTable4"}>
                    <thead>
                    <tr>
                      <th/>
                      {stats.insurancesList?.map(insurance => <th>{insurance.replace("zzz", "")}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th>{text.TOTAL}</th>
                      {stats.insurancesList?.map(insurance => {
                            return (
                                <td>
                                  <td className={"rawNumber"}>{(stats.insuranceStat[insurance] ?? 0) + (stats.canceledInsuranceStat[insurance] ?? 0)}</td>
                                  <td className={"separator"}>&nbsp;|&nbsp;</td>
                                  <td className={"percentRate"}>100%</td>
                                </td>
                            )
                          }
                      )}
                    </tr>
                    <tr>
                      <th>{text.CANCELED}</th>
                      {stats.insurancesList?.map(insurance => {
                            return (
                                <td>
                                  <td className={"rawNumber"}>{stats.canceledInsuranceStat[insurance] ?? 0}</td>
                                  <td className={"separator"}>&nbsp;|&nbsp;</td>
                                  <td className={"percentRate"}>{roundValue((stats.canceledInsuranceStat[insurance] ?? 0) / ((stats.insuranceStat[insurance] ?? 0) + (stats.canceledInsuranceStat[insurance] ?? 0)) * 100)}%</td>
                                </td>
                            )
                          }
                      )}
                    </tr>
                    <tr>
                      <th>{text.TOTAL} hors {text.CANCELED}</th>
                      {stats.insurancesList?.map(insurance => {
                            return (
                                <td>
                                  <td className={"rawNumber"}>{stats.insuranceStat[insurance] ?? 0}</td>
                                  <td className={"separator"}>&nbsp;|&nbsp;</td>
                                  <td className={"percentRate"}>{roundValue((stats.insuranceStat[insurance] ?? 0) / ((stats.insuranceStat[insurance] ?? 0) + (stats.canceledInsuranceStat[insurance] ?? 0)) * 100)}%</td>
                                </td>
                            )
                          }
                      )}
                    </tr>
                    <tr>
                      <th>Ratio par rapport au cumul</th>
                      {stats.insurancesList?.map(insurance => <td
                          className={"percentRate"}>{roundValue(((stats.insuranceStat[insurance] ?? 0) + (stats.canceledInsuranceStat[insurance] ?? 0)) / stats.total * 100)}%</td>)}
                    </tr>

                    </tbody>
                  </table>
                </div>
                <Chart palette={graphColors} dataSource={insuranceStats} onPointClick={null}
                       onDone={() => setGraphSize("100%")}>
                  {graphSize && <Size width={graphSize} height={graphSize}/>}
                  <ChartTitle text="Volumétrie par assurance">
                    <ChartFont family="rubik" weight={600} size={20}/>
                  </ChartTitle>
                  <ChartCommonSeriesSettings argumentField="insurance" type="stackedBar">
                    <ChartLabel>
                      <Font family="rubik" weight={600} size={16}/>
                    </ChartLabel>
                  </ChartCommonSeriesSettings>
                  <Tooltip enabled={true} customizeTooltip={customizeTooltipForInsurance} cornerRadius={50}
                           zIndex={1000}
                           opacity={0.4} shadow={{opacity: 0.2}} border={{color: "rgba(0,0,0,.5)"}}>

                    <ChartFont family="rubik" weight={600} size={16}/>
                  </Tooltip>

                  <ChartSeries argumentField="insurance" valueField="achieved" name="Réalisées"/>
                  <ChartSeries argumentField="insurance" valueField="canceled" name="Annulées"/>


                  <ChartLegend verticalAlignment="bottom" horizontalAlignment="center">
                    <ChartFont family="rubik" weight={600} size={16}/>
                  </ChartLegend>

                  <ChartExport enabled={true}/>
                </Chart>
              </div>
              <div className={"PartTabGraph"}>
                <table className={"statsTable statsTable4"}>
                  <thead>
                  <tr>
                    <th/>
                    <th>{text.DONE_BY_CALENDAR}</th>
                    <th>{text.DONE_BY_BUSINESS}</th>
                    <th>{text.CUMUL_BY_CALENDAR}</th>
                    <th>{text.CUMUL_BY_BUSINESS}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <th>{text.TOTAL}</th>
                    <td>
                      <td className={"rawNumber"}>{delay.j4_c_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>100%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j4_c_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>100%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j4_c_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>100%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j4_c_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>100%</td>
                    </td>
                  </tr>
                  <tr>
                    <th>{text.J0}</th>
                    <td>
                      <td className={"rawNumber"}>{delay.j0_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j0_c / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j0_b}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j0_b / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j0_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j0_c / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j0_b}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j0_b / delay.j4_c_c * 100)}%</td>
                    </td>
                  </tr>
                  <tr>
                    <th>{text.J1}</th>
                    <td>
                      <td className={"rawNumber"}>{delay.j1_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j1_c / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j1_b}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j1_b / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j1_c_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j1_c_c / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j1_b_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j1_b_c / delay.j4_c_c * 100)}%</td>
                    </td>
                  </tr>
                  <tr>
                    <th>{text.J2}</th>
                    <td>
                      <td className={"rawNumber"}>{delay.j2_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j2_c / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j2_b}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j2_b / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j2_c_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j2_c_c / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j2_b_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j2_b_c / delay.j4_c_c * 100)}%</td>
                    </td>
                  </tr>
                  <tr>
                    <th>{text.J3}</th>
                    <td>
                      <td className={"rawNumber"}>{delay.j3_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j3_c / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j3_b}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j3_b / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j3_c_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j3_c_c / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j3_b_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue(delay.j3_b_c / delay.j4_c_c * 100)}%</td>
                    </td>
                  </tr>
                  <tr>
                    <th>{text.J4}</th>
                    <td>
                      <td className={"rawNumber"}>{delay.j4_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue((delay.j4_c_c - delay.j3_c_c) / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j4_b}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>{roundValue((delay.j4_c_c - delay.j3_b_c) / delay.j4_c_c * 100)}%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j4_c_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>100%</td>
                    </td>
                    <td>
                      <td className={"rawNumber"}>{delay.j4_c_c}</td>
                      <td className={"separator"}>&nbsp;|&nbsp;</td>
                      <td className={"percentRate"}>100%</td>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <Chart palette={graphColors} dataSource={delayStats} onPointClick={null}
                       onDone={() => setGraphSize("100%")}>
                  {graphSize && <Size width={graphSize}/>}
                  <ChartTitle text="Délai d'intervention (hors SAV)">
                    <Font family="rubik" weight={600} size={20}/>
                  </ChartTitle>
                  <CommonAxisSettings>
                    <Label>
                      <Font family="rubik" weight={600} size={16}/>
                    </Label>
                  </CommonAxisSettings>
                  <Tooltip enabled={true} customizeTooltip={customizeTooltipForDelay} cornerRadius={50} zIndex={1000}
                           opacity={0.4} shadow={{opacity: 0.2}} border={{color: "rgba(0,0,0,.5)"}}>

                    <Font family="rubik" weight={600} size={16}/>
                  </Tooltip>
                  <ChartSeries argumentField="dayN" valueField="calendarDay" name="Jours calendaires"/>
                  <ChartSeries argumentField="dayN" valueField="businessDay" name="Jours ouvrés"/>
                  <ChartSeries argumentField="dayN" valueField="calendarCumul" name="Cumul des jours calendaires"/>
                  <ChartSeries argumentField="dayN" valueField="businessCumul" name="Cumul des jours ouvrés"/>
                  <ChartLegend verticalAlignment="bottom" horizontalAlignment="center">
                    <Font family="rubik" weight={600} size={16}/>
                  </ChartLegend>
                  <ChartExport enabled={true}/>
                </Chart>
              </div>
            </div>}
          </div>
        </section>
    </div>
  )
}
