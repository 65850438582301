import IbatApi from "../../utils/IbatApi";
import Modal from "react-responsive-modal";
import {Fragment, useEffect, useLayoutEffect, useState} from "react";
import CustomImage from "./CustomImage";
import PDFIcon from "../../images/pdf-icon.png"
import  "../style/DocumentLodaerStyle.css"
import Loading from "./Loading";
import {Tooltip} from "@material-ui/core";
import { text } from "../util/constants";

export default function DocumentLoader({name} ){
    const api=new IbatApi()
    const [show,setShow]=useState(false)
    const [doc,setDocument]=useState()


    function openModal() {
      api.getFile(name)
        .then(resp=>{
          setDocument(
            name.split(".").pop().toLowerCase() === "pdf" ?
            resp.b64.replace("octet-stream", "pdf") :
            resp.url
          )
        })
    }

    return(
      <div className={"docLoaderContainer"}>
        <div className={"documentLoader"}>
          {name.split(".").pop().toLowerCase() === "pdf" ?
            <img src={PDFIcon} onClick={openModal}/> :
            <CustomImage className={"customImage"} name={name} width={30} height={40} onClick={openModal}/>}
          <Modal open={doc} onClose={() => setDocument(null)}>
            {name.split(".").pop().toLowerCase() === "pdf" ?
              <embed className={"customModalPDF"} src={doc}/> :
              <CustomImage className={"customImageModal"} name={name}/>}
          </Modal>
        </div>
      </div>

    )
}

export function DocumentLoaderList({name} ){
    return(
        <div className={"documentLoaderList"}>
            { name?.map(n=><DocumentLoader name={n}/>)}
        </div>
    )
}
