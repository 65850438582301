import React, {useState, useLayoutEffect, useEffect} from 'react';
import IbatApi from "../../utils/IbatApi";
import InterventionForm from "../forms/InterventionForm";
import InteractionClient from "../bean/InteractionClient";
import EditInterventionDate from "../forms/EditInterventionDate";
import IconEye from "../../images/contact-interaction.png";
import Table from "../../table/Table";
import EditBillForm from "../forms/EditBillForm";
import {IconButton} from "@material-ui/core";
import Modal from "react-responsive-modal";
import Tooltip from "@material-ui/core/Tooltip";
import IconMap from "../../images/button-map.png";
import CSVDl from "../../images/csv-dl.png";
import STATS from "../../images/statistiques.png";
import MapBean from "./MapBean";
import {useSnackbar} from "notistack";
import { text } from "../util/constants";
import EditEstimate from "../../estimate/EditEstimate";
import {Statistiques} from "../../statistiques/Statistiques";




export default function Interventions({defaultFilter}) {
    const {enqueueSnackbar,closeSnackbar}=useSnackbar()

    const columns = [
        {id: "id", label: text.NUMBER, isChecked: true, isFiltrable: true, type: "linkText"},
        {id: "status", label: text.STATUS, isChecked: true, isFiltrable: true, type: "text"},
        {id: "type", label: text.FILE_TYPE, isChecked: true, isFiltrable: true, type: "object"},
        {id: "insurance", label: text.INSURANCE, isChecked: true, isFiltrable: false, type: "insurance"},
        {id: "devis", label: text.COMMAND_NUM, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "reception", label: text.RECEPTION_DATE, isChecked: true, isFiltrable: true, type: "text"},
        {id: "company", label: text.COMPANY_NAME, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "site", label: text.SITE, isChecked: true, isFiltrable: true, type: "objectLink"},
        {id: "address", label: text.ADDRESS, isChecked: true, isFiltrable: true, type: "text"},
        {id: "cp", label: text.ZIPCODE, isChecked: true, isFiltrable: true, type: "text"},
        {id: "ville", label: text.TOWN, isChecked: true, isFiltrable: true, type: "text"},
        {id: "interactions", label: text.INTERACTIONS, isChecked: true, isFiltrable: true, icon: IconEye, type: "popup", popup: InteractionClient},
        {id: "intervention", label: text.INTERVENTION, isChecked: true, isFiltrable: true, type: "dateText"},
        // {id: "estimateDuration", label: text.DURATION_ESTIMATION, isChecked: true, isFiltrable: false, type: "popup", popup: EditEstimateDuration},
        {id: "estimate", label: text.ESTIMATE, isChecked: true, isFiltrable: true, type: "popup", popup: EditEstimate},
        {id: "bill", label: text.BILL, isChecked: true, isFiltrable: true, type: "popup", popup: EditBillForm},

    ]


    const [state, setState] = useState(
        {
            columns: columns,
            interventionList: [],
            pageSize: 20,
            pageNumber: 0,
            count: 0,
            filters: {
                id: "",
                status: defaultFilter?defaultFilter:"",
                type: "",
                site: "",
                client: "",
                supply: "",
                team: "",
                reception: "",
                intervention: "",
                devis: "",
                sort:"",
            }
        }
    )
    const [typing,setTyping]=useState(false)
    const [stats,setStats]=useState(false)
    const [map,setMap]=useState(false)
    const [loading,setLoading]=useState(false)
    const api = new IbatApi()


    function getInterventions() {

        if (!isFilterEmpty()){
            setLoading(true)
            api.getFilterIntervention({
                ...state.filters,
                pageNumber: state.pageNumber,
                pageSize: state.pageSize
            }).then(res => {
                setLoading(false)
                setState( ({...state, interventionList: res.interventions, count: res.count}))
            }).catch(e=> {
                    if (e.response?.status === 401) {
                        enqueueSnackbar(text.PLEASE_LOGIN, {variant: "error"})
                        // window.open("/login")
                        return
                    }
                enqueueSnackbar(text.SNACKBAR_ERROR, {variant: "error"})
            })
        }
    }


    useEffect(() => {
        getInterventions()
    }, [typing,state.pageNumber,state.pageSize])


    function isFilterEmpty() {
        // let reduce = Object.keys(state.filters).map((k)=> state.filters[k]==="").reduce((b1, b2) => b1 && b2);
        return false
    }


    const handleColumns = (event) => {
        let columns = state.columns
        let filter = {...state.filters}
        let find = columns.find(col => col.id === event.target.name);
        find.isChecked = event.target.checked
        if (find.isFiltrable) {
            filter[find.id] = ""
        }
        setState( ({...state, columns: columns, filters: filter, mapping: false}))
    }

    const handleFilter = (e) => {
        let value = e.target.value
        let name = e.target.name
        setState( ({...state, filters: {...state.filters, [name]: value,sort: name}}))
    }

    const handleChangePage = (newPage) => {
         setState( ({...state, pageNumber: newPage}))
    };

    const handleChangeRowsPerPage = (event) => {
        setState( ({...state, pageSize: event.target.value, pageNumber: 0}))
    };

    function clearFilter() {
        setState({
            ...state, filters: {
                id: "",
                status: "",
                type: "",
                site: "",
                client: "",
                supply: "",
                team: "",
                reception: "",
                intervention: "",
                devis: "",
                sort: "",
                maps: false
            }
        })
        setTyping(!typing)
    }

    function generatecsv() {
        api.generateCsv(state.filters,state.columns.filter(c=>c.isChecked))
    }

    return (
        <div>
            {console.log(state.interventionList)}
            <Table onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                   loading={loading}
                   onHandleChangePage={handleChangePage}
                   onGetData={getInterventions}
                   onHandleColumns={handleColumns}
                   onHandleFilter={handleFilter}
                   rowCount={state.count}
                   onEndTyping={setTyping}
                   filter={state.filters}
                   pageNumber={state.pageNumber}
                   pageSize={state.pageSize}
                   columns={state.columns}
                   list={state.interventionList}
                   onClearFilters={clearFilter}
                   mapdisplay={true}
                   tabName={text.INTERVENTIONS}
            >
                <Tooltip title={text.TOOLTIP_DISPLAY_INTERVENTIONS_MAP}>
                    <IconButton disabled={isFilterEmpty()} onClick={()=>setMap(true)}><img alt={"img"} src={IconMap }/></IconButton>
                </Tooltip>
                <InterventionForm onSaveSucceed={getInterventions}/>
                <Tooltip title={text.CSV}>
                    <IconButton onClick={generatecsv}><img alt={"CSV"} src={CSVDl}/></IconButton>
                </Tooltip>
                <Tooltip title={text.GENERAL_STATS}>
                    <IconButton onClick={()=>setStats(true)}><img alt={"Statistiques"} src={STATS}/></IconButton>
                </Tooltip>





            </Table>
            <Modal open={stats} onClose={()=>setStats(false)} closeOnOverlayClick={false} >
              <Statistiques onFinish={()=>setStats(false)}/>
            </Modal>

            <Modal open={map} onClose={()=>{clearFilter();setMap(false)}} >
                <div className={"map"}>
                    <MapBean
                        onHandleChangeRowsPerPage={handleChangeRowsPerPage}
                        loading={loading}
                        onHandleChangePage={handleChangePage}
                        onGetData={getInterventions}
                        onHandleColumns={handleColumns}
                        onHandleFilter={handleFilter}
                        rowCount={state.count}
                        onEndTyping={setTyping}
                        filter={state.filters}
                        pageNumber={state.pageNumber}
                        pageSize={state.pageSize}
                        columns={state.columns}
                        pinList={state.interventionList?state.interventionList:[]} pinClickable={true}/>
                </div>
            </Modal>


        </div>
    )
}


