import React, {useState} from "react";
import IbatApi from "../../utils/IbatApi";
import CreatableSelect from "react-select/creatable/dist/react-select.esm";
import {Modal} from "react-responsive-modal";
import ClientForm from "./ClientForm";
import Select from "react-select";
import {useSnackbar} from "notistack";
import { text } from "../util/constants";

export default function SearchClient({onSave}) {
    const [clientList, setClientList] = useState([])
    const [client, setClient] = useState()
    const [popup, setPopup] = useState(false)
    const [name, setName] = useState()
    let api = new IbatApi();

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    let loadOptions=(inptv)=>{
        return api.findClient(inptv).then(resp => {
            let map = resp.map(s => ({label: s.name, name: s.name, id: s.id,value:s.id}))
            setClientList(map)
            return map
        })
    }

    function handleInputChange(d){
        setName(d)
        loadOptions(d)
    }

    function handleCreate(d){
        setName(d)
        setPopup(true)
    }

    function handleClient(data){
        return api.createClient(data)
            .then(res => {
            setClient({value:res.id,label:res.name})
            onSave({id:res.id,name:res.name})
            setPopup(false)
            })
    }

    function handleCancel(){
        setClient(null)
        setPopup(false)

    }

    function handleChange(d){
        console.log(d)
        if(d!==null){
            onSave({id:d.value,name:d.label})
            setClient({value:d.value,label:d.label})
        }
    }
    return (
        <div className={"createSite"}>
            <CreatableSelect
                classNamePrefix={"search"}
                placeholder={text.CLIENT_SEARCH}
                isClearable
                onChange={handleChange}
                onInputChange={handleInputChange}
                isLoading={popup}
                onCreateOption={handleCreate}
                value={client}
                formatCreateLabel={(input)=>`${text.CREATE} ${input}`}
                noOptionsMessage={()=>text.NO_ENTRY_SLECTED}
                options={clientList}
            />
            <Modal open={popup} onClose={() => setPopup(false)}>
                <ClientForm name={name} onCancel={handleCancel} onSave={handleClient}/>
            </Modal>

        </div>
    )
}
