import React, {useState} from "react";
import "../style/contactFormStyle.css"
import { text } from "../util/constants";

export default function  ContactForm({onChange}) {


    const [state,setState]=useState()
    function handleChange(e) {
        const name = e.target.name
        setState({...state,
            [name]: e.target.value

        })
        console.log({...state, [name]: e.target.value})
        onChange({...state, [name]: e.target.value})

    }

     return (

            <div className={"contactForm"}>
                <div>
                    <input type={"text"}
                           name={"name"}
                           placeholder={text.CONTACT_ONSITE}
                           value={state?.name}
                           onChange={handleChange}/>
                </div>

                <div>
                    <input type={"text"} name={"phone"}
                           pattern="[0-9]{10};*"
                           placeholder={text.PHONE_ONSITE}
                           value={state?.phone}
                           onChange={handleChange}/>
                </div>
                <div>
                    <input type={"text"}  name={"phone2"}
                           pattern="[0-9]{10};*"
                           placeholder={text.PHONE2_ONSITE}
                           value={state?.phone2}
                           onChange={handleChange}/>
                </div>

                <div id={"status"}>
                    <input type={"text"} id={"status"} name={"status"} value={state?.status}
                           placeholder={text.ONSITE_CONTACT_FUNCTION}
                           onChange={handleChange}/>
                </div>
            </div>

        )
}
