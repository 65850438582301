import SearchTeam from "./SearchTeam";
import React, {Fragment, useEffect, useState} from "react";
import IbatApi from "../../utils/IbatApi";
import SearchSite from "./SearchSite";
import DevisForm from "./DevisForm";
import { text } from "../util/constants";
import {isClassic, isSpecialIntervention} from "../../helpers/interventionManager";

export default function CreateInterventionForm({onValid,children,onSiteChange,classic}){
    const default_state={
        jobTypeId: 1
    }
    let api = new IbatApi();

    useEffect(() => {
        api.getSecteur().then(secteur => setSecteur(secteur))
        api.getTypes().then(types => setTypes(types))
    }, [])
    const [state, setState] = useState(default_state)
    function handleTeam(data){
        setState({...state,team:data})
        onValid({...state,team:data})
    }

    function handleType(e) {
        setState({...state, type: e.target.value})
        onValid({...state, type: e.target.value})
    }

    function handleSecteur(e) {
        setState({...state, secteur: e.target.value})
        onValid({...state, secteur: e.target.value})

    }

    function handleIntervention(e) {
        setState({...state, intervention: e.target.value})
        onValid({...state, intervention: e.target.value})
    }

    const [types, setTypes] = useState()
    const [secteur, setSecteur] = useState()


    function handleEndIntervention(e) {
        let value = e.target.value * 3600*24*1000;
        console.log(value)
        setState({...state, dateEndIntervention: value})
        onValid({...state, dateEndIntervention: value})

    }

    function handleEstimatedDuration(e) {
        let [h,m]=e.target.value.split(":")
        let value=3600*h+60*m
        setState({...state, estimateDuration: value})
        onValid({...state, estimateDuration: value})
        console.log(value)

    }

    return (
        <Fragment>
            <div title={text.FILE_TYPE}>
                <select required onChange={handleType} className={"cmdCreatInput"}>
                    <option defaultValue="" disabled selected>{text.FILE_TYPE}</option>
                    {types && types.sort((t1,t2)=>t1.name>t2.name?1:-1).filter(t=>!classic || isClassic(t.id))
                        .map(t => <option value={t.id}>{t.name}</option>)}
                </select>
            </div>
            {!isSpecialIntervention(state.type) &&  children}
            {!classic && onSiteChange &&
            <div title={text.SITE} className={"cell commande-search"}>
                <SearchSite className={"cmdCreatInput"} onSave={onSiteChange}/>
            </div>}
            {(state.type)&&
                <div title={text.ZONE} >
                    <select required onChange={handleSecteur}>
                        <option defaultValue="" disabled selected>{text.ZONE}</option>
                        {secteur && secteur.map(s => <option value={s.id}>{s.name}</option>)}
                    </select>
                </div>}

            {(state.secteur)&&
                <div title={text.TEAM} className={"search"}>
                    <SearchTeam onSave={handleTeam} value={state.team?state.team:{id: "", name: ""}}/>
                </div>
                }
            {(state.team)&&
            <div title={text.INTERVENTION_START}>
                <input type={"datetime-local"} value={state.intervention} onChange={handleIntervention}/>
            </div>}
            {(state.intervention && state.type==6)&& //todo envar depot
                <div title={text.DURATION}>
                    <input type={"time"}  onChange={handleEstimatedDuration}/>
                </div>
            }

            {(state.estimateDuration )&&
            <div title={text.SUPPLY_FOR}>
                <input type={"number"} onChange={handleEndIntervention}/> {text.DAYS}
            </div>}
        </Fragment>


    )
}