import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import IbatApi from "../../utils/IbatApi";
import "../style/SiteStyle.css"
import IconSite from "../../images/site-name.png"
import IconBuger from "../../images/history.png"
import UpdateSiteImage from "../forms/UpdateSiteImage";
import {IconButton} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {text} from "../util/constants";
import {getColor, isModifiable} from "../../helpers/interventionManager";


export default function Site() {

  const {id} = useParams()

  let api = new IbatApi();
  const [state, setState] = useState({})
  const [interventions, setinterventions] = useState([])
  useEffect(() => {
    getData()
  }, [id])



  function getData() {
    api.getSite(id).then(data => setState({...state, ...data}))
    api.getSiteIntervention(id).then(i => setinterventions(i))
  }

  return (
    <div className={"site-bean"}>
      <div className={"site"}>
        <h1><img src={IconSite}/>{text.SITE} {id}</h1>
        <div>
          <div>
            <h2>{state.name ? state.name : text.NO_NAME}</h2>
            <p>{state.address}<br/> {state.cp} {state.ville} <br/> {state.status}<br/>{state.contactName}<br/>
              <ul>{state.contactPhone?.map(ph => <li>{ph}</li>)}</ul>
            </p>
          </div>
          {<UpdateSiteImage voie={state.address + " " + state.cp + " " + state.ville}
                            img={state.imageUrl ? state.imageUrl : null} siteId={id} latitude={state.latitude}
                            longitude={state.longitude} update={getData}/>}
        </div>
      </div>
      <div className={"interventions"}>
        <h3>
          {text.INTERVENTION_HISTORY} ({interventions.length > 0 ? interventions.length : 0})
        </h3>
        <div className={"table"}>
          <table>
            <thead>
            <tr>
              <th>{text.ID}</th>
              <th>{text.FILE_TYPE}</th>
              <th>{text.STATUS}</th>
              <th>{text.RECEPTION_DATE}</th>
              <th>{text.INTERVENTION_START}</th>
              <th>{text.SERVICE_PROVIDER}</th>
            </tr>
            </thead>
            <tbody>
            {interventions.map(
              (value, k) => {
                return (
                  <tr key={k}>
                    <td><a href={`/intervention/${value.id}`}> {value.id}</a></td>
                    <td>{value.type.name}</td>
                    <td>{text.STATUS_TRADUCTION[value.status]}</td>
                    <td>{value.devis.dateReception ? new Date(value.devis.dateReception).toLocaleDateString() : "-"}</td>
                    <td>{value.dateIntervention > 0 ? new Date(value.dateIntervention).toLocaleDateString() : "-"}</td>
                    <td><a href={`/company/${value.devis?.client?.id}`}>{value.devis?.company?.name}</a></td>
                  </tr>
                )
              }
            )}
            </tbody>

          </table>
        </div>
      </div>
    </div>
  )
}
