import CreateIntervention from "./CreateIntervention";
import React, {Fragment, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import CreateDevisIcon from"../../images/add-devis.png"
import SearchSite from "./SearchSite";
import {Modal} from "react-responsive-modal";
import SearchClient from "./SearchClient";
import DevisForm from "./DevisForm";
import { text } from "../util/constants";

export default function CreateDevis({onChange}){


    const default_state={
        acompte:0,
        comments:"",
        dateReception:0,
        montant:0,
        clientIdBCommande:"",
        devisData:[],
        documents:[],
        supplyDocument:[],
        supplyAdminDocument:[],
        supplyMachinery:[],
        clientId:0,
        myUserId:0,
        interventionApi:{},

        client:{},
        site:{},
        reception: 0,
        companyId:1,
        intervention:0
    }

    const [temp,setTemp]=useState(default_state)
    const [state, setState] = useState(default_state)
    const [devis,setDevis]=useState(false)

    function handleDevis(data) {
        setDevis(false)
        setState({...state, ...data})
        onChange({...state,  ...data})
    }

    function handleSite(data) {
        setState({...state, site: data,siteId:data.id})
        onChange({...state,site: data, siteId: data.id})

    }

    function handleInterventionData(data){
        setState({...state,intervention:data})
        onChange({...state,intervention:data})
        console.log(data)
    }

    function handleReception(e) {
        setState({...state, reception: e.target.value})
        onChange({...state, reception: e.target.value})
    }

    function handleClient(data) {
        console.log(data)
        setState({...state, client: data,clientId: data.id})
        onChange({...state, clientId: data.id})
    }

    function handleDevisFormSave(devis,metadata,comments,documents){
        let tempData={...temp,
            clientIdBCommande : devis.clientIdBCommande,
            documents : documents,
            acompte:devis.acompte,
            montant:devis.montant,
            devisData:metadata.devisData,
            supplyDocument: metadata.supplyDocument,
            supplyAdminDocument: metadata.supplyAdminDocument,
            supplyMachinery: metadata.supplyMachinery,
            comments:comments
        }
        setTemp(tempData)
    }


    return(
        <div className={"commande-data"}>
            <CreateIntervention onValid={handleInterventionData} onSiteChange={handleSite}>

                <Fragment>
                    <div title={text.BILL_CMD}>
                        <input type={"text"} value={state.clientIdBCommande} placeholder={text.REF_NUM}
                               onChange={(e) => setState({...state, clientIdBCommande: e.target.value})}/>
                        <IconButton onClick={() => setDevis(true)}><img src={CreateDevisIcon}/></IconButton>
                    </div>

                    <Modal  open={devis} onClose={() => setDevis(false)}>
                        <DevisForm temp={temp} onSave={handleDevisFormSave} trigger={handleDevis}/>
                    </Modal>


                    <div title={"Date"}>
                        <input className={"datetimeInput"} type={"datetime-local"} value={state.reception} onChange={handleReception}/>
                    </div>

                    <div title={"Client"} className={"cell commande-search"}>
                        <SearchClient onSave={handleClient}/>
                    </div>
                </Fragment>

            </CreateIntervention>


        </div>
    )
}