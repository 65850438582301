import React, {useEffect, useState} from "react";
import "../style/clientFormStyle.css"
import IllustrationClient from "../../images/button-new-client.png"

import IbatApi from "../../utils/IbatApi";
import IconButton from "@material-ui/core/IconButton";
import {useSnackbar} from "notistack";
import {text} from "../util/constants";

export default function ClientForm({onSave,onCancel,id,name,phone,mail,address,address2,cp,ville,idGestionnaire}) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [state, setState] = useState({
        name: "",
        phone: "",
        mail: "",
        poste: "",
        cp: null,
        ville:"",
        idGestionnaire: "",
        address: "",
        address2: "",
    })

    const [gestionnaire, setGestionnaire] = useState()
    const api = new IbatApi()
    useEffect(() => {
        setState({...state,
            name:name?name:"",
            id:id?id:"",
            phone:phone?phone:"",
            mail:mail?mail:"",
            address:address?address:"",
            address2:address2?address2:"",
            ville:ville?ville:"",
            cp:cp?cp:null,
            idGestionnaire:idGestionnaire?idGestionnaire:-2
        })
        api.getGestionnaire().then(gest => setGestionnaire(gest))
    }, [])


    function handleChange(e) {
        const name = e.target.name
        setState({
            ...state,
            [name]: e.target.value
        })
    }

    function isSavable() {
        return state?.name !== "" ;
    }

    function handleSave() {
        onSave(state)
            .then(r => enqueueSnackbar(text.SNACKBAR_SAVED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(text.SNACKBAR_ERROR,{variant:"error"}))

    }

    function handleCancel() {
        onCancel()
    }

    return (
            <div className={"clientForm"}>
                <div className={"child"}>
                    <h2> <img alt={"illus"} className={"illustration"} src={IllustrationClient}/>Création Client</h2>


                    <div className={"client-data"}>
                        <input type={"text"} id={"name"} name={"name"} placeholder={text.CLIENT_NAME} value={state.name}
                               onChange={handleChange}/>

                        <div className={"client-address"}>
                            <input type={"text"} id={"address"} name={"address"} placeholder={text.ADDRESS}
                                   value={state.address} onChange={handleChange}/>
                            <input type={"text"} id={"address2"} name={"address2"} placeholder={text.ADDRESS2}
                                   value={state.address2} onChange={handleChange}/>
                            <input type={"number"} id={"cp"} name={"cp"} placeholder={text.ZIPCODE}
                                   value={state.cp} onChange={handleChange}/>
                            <input type={"text"} id={"ville"} name={"ville"} placeholder={text.TOWN}
                                   value={state.ville} onChange={handleChange}/>
                        </div>

                        <input type={"tel"} id={"phone"} name={"phone"} placeholder={text.CLIENT_PHONE} value={state.phone}
                               onChange={handleChange}/>

                        <input type={"email"} id={"mail"} name={"mail"} placeholder={text.CLIENT_MAIL} value={state.mail}
                               onChange={handleChange}/>

                        <input type={"text"} id={"poste"} name={"poste"} placeholder={text.CLIENT_FUNCTION} value={state.poste}
                               onChange={handleChange}/>

                        <select required name={"idGestionnaire"} onChange={handleChange}>
                            <option defaultValue={state.idGestionnaire} disabled selected>Gestionnaire Client </option>
                            {gestionnaire && gestionnaire.map(s => <option value={s.id}>{s.name}</option>)}
                        </select>
                    </div>



                    <div className={"wrapper"}>
                        {isSavable() ? <IconButton className={"validation-button"} onClick={handleSave}>{text.ADD_BUTTON}</IconButton> :
                            <IconButton className={"cancel-button"} onClick={handleCancel}>{text.CANCEL}</IconButton>}
                    </div>
                </div>
            </div>
    )
}
