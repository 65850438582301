import {Fragment, useState} from "react";
import FileUploader from "../util/FileUploader";
import {IconButton} from "@material-ui/core";
import {useSnackbar} from "notistack";
import "../style/SignInFormStyle.css"
import IbatApi from "../../utils/IbatApi";
import Tooltip from '@material-ui/core/Tooltip';
import { text } from "../util/constants";



export default function SignInForm(){
    const [user,setUser]=useState({username:"",password:"",confirmPassword:""})
    const [company,setCompany]=useState({company:"",siren:"",address:"",mail:"",ville:"",cp:""})
    const [logo,setLogo]=useState()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const api = new IbatApi();


    function handleLogo(mlogo){
        setLogo(mlogo)
    }

    function handleUserChange(e){
        const name =e.target.name
        const value =e.target.value
        setUser({...user,[name]:value})
    }

    function handleCompanyChange(e){
        const name =e.target.name
        const value =e.target.value
        setCompany({...company,[name]:value})
    }
    function subscribe(){
        if (!user.username || !user.password || !company.company || !company.siren || !company.address || !company.mail || !company.cp || !company.ville || !logo){
            console.log(user.username ,user.password ,company.company ,company.siren ,company.address ,company.mail,company.cp , company.ville ,logo)
            enqueueSnackbar(text.FILL_ALL_INPUTS,{variant:"warning"})
            return
        }
        if (user.password !== user.confirmPassword){
            enqueueSnackbar(text.SNACKBAR_PASSWORD_ERROR,{variant:"error"})
            return
        }
        api.subscribe({user:user,company:{...company,logo:logo}})
            .then(()=>enqueueSnackbar(text.COMPANY_CREATED,{variant:"success"}))
            .catch(e=>enqueueSnackbar(e.message,{variant:"error"}))
    }
    return(
            <div className={"company-creation"}><h2>{text.H2_SUBSCRIBE}</h2>
              <div className={"owner"}>
                <Tooltip title={text.TOOLTIP_ENTER_USERNAME} aria-label="!">
                    <input type={"text"} value={user.username} name={"username"} placeholder={text.ENTER_USERNAME} onChange={handleUserChange}/>
                </Tooltip>
                <Tooltip title={text.TOOLTIP_PASSWORD}>
                    <input type={"password"} value={user.password} name={"password"} placeholder={text.ENTER_PASSWORD} onChange={handleUserChange}/>
                </Tooltip>
                    <input type={"password"} value={user.confirmPassword} name={"confirmPassword"} placeholder={text.CONFIRM_PASSWORD} onChange={handleUserChange}/>
                </div>
             <div className={"company"}>
                <Tooltip title={text.TOOLTIP_SIREN}>
                    <input type={"text"} value={company.siren} name={"siren"} placeholder={text.SIREN} onChange={handleCompanyChange}/>
                </Tooltip>
                <input type={"text"} value={company.company} name={"company"} placeholder={text.COMPANY_NAME} onChange={handleCompanyChange}/>
                <Tooltip title={text.TOOLTIP_COMPANY}>
                    <input type={"text"} value={company.address} name={"address"} placeholder={text.ADDRESS} onChange={handleCompanyChange}/>
                </Tooltip>
              <div className={"cpostal"}><input type={"text"} value={company.cp} name={"cp"} placeholder={text.ZIPCODE} onChange={handleCompanyChange}/>
                    <input type={"text"} value={company.ville} name={"ville"} placeholder={text.TOWN} onChange={handleCompanyChange}/></div>
                <Tooltip title={text.TOOLTIP_ENTER_EMAIL}>
                    <input type={"text"} value={company.mail} name={"mail"} placeholder={text.MAIL} onChange={handleCompanyChange}/>
                    </Tooltip>
                <Tooltip title={text.TOOLTIP_LOGO_ADD}>
                        <div className={"logo-to-upload"}>
                       {text.COMPANY_LOGO}
                        <FileUploader files={[]} onUpload={handleLogo} single/>
                    </div>
                </Tooltip>
                </div>
                <IconButton onClick={subscribe} className={"validation-button"}>{text.SUBSCRIBE}</IconButton>
                <a href={"/login"}>{text.RETURN_TO_LOGIN}</a>
            </div>
    )
}