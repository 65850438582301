import React, {useState, useEffect, Fragment,} from "react";
import {useParams} from "react-router-dom"
import {useHistory} from 'react-router-dom';
import Operation from "../../operation/Operation";
import IbatApi from "../../utils/IbatApi";
import "../style/interventionStyle.css"
import BonCommande from "../../images/bc-icon.png"
import IntervIcon from "../../images/new-interv.png"
import ValidateIcon from "../../images/validate-icon.png"
import FinishIcon from "../../images/finish-icon.png"
import InteractionIcon from "../../images/icon-contact.png";
import SupplyIcon from "../../images/supply-icon.png";
import RoofIcon from "../../images/roof.png"
import RiskIcon from "../../images/warning.png"
import Icon from "../../images/interv-illus.png"
import InfoIcon from "../../images/info.png"
import CaseIcon from "../../images/case2.png"
import PlanIcon from "../../images/plans.png"
import GenPdfIcon from "../../images/illust-button-pdf.png"
import FuiteIcon from "../../images/fuite-icon.png"
import DocumentIcon from "../../images/file.png"
import SignatureIllust from "../../images/noSignature-icon.png"
import SignatureIcon from "../../images/signature-icon.png"
import CustomImage from "../util/CustomImage";
import Risk from "./Risk";
import {IconButton} from "@material-ui/core";
import Modal from "react-responsive-modal";
import Layer from "../../layer/Layer";
import CancelIcon from "../../images/cancel-interv-icon.png"
import {DocumentLoaderList} from "../util/DocumentLoader";
import InteractionClient from "./InteractionClient";
import EditSupply from "../forms/EditSupply";
import constants from "../util/constants";
import {text} from "../util/constants";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import PdfBean from "./PdfBean";
import {useSnackbar} from "notistack";
import {isSpecialIntervention} from "../../helpers/interventionManager";
import WorkForceDisplayer from "../../work-force-displayer/WorkForceDisplayer";
import ImageManquante from "../../images/map-add.png";
import Tooltip from "@material-ui/core/Tooltip";

export default function Intervention() {
  const api = new IbatApi();
  const {id} = useParams()
  const [state, setState] = useState({})
  const [modal, setModal] = useState(false)
  const [openTeamInteraction, setOpenTeamInteraction] = useState(false)
  const [openSupply, setOpenSupply] = useState(false)
  const [pdfPopup, setPdfPoup] = useState(false)
  const [object, setObject] = useState("")
  const [conclusion, setConclusion] = useState("")
  const [resume, setResume] = useState("")
  const [status, setStatus] = useState([])
  const [operations, setOperations] = useState([])
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();

  const history = useHistory()

  useEffect(() => {
    getIntervention()
    api.getStatus().then(s => {
      setStatus(s)
    })
  }, [id])

  function getIntervention() {
    return api.getIntervention(id).then(i => {
      console.log(i)
      setState({...state, ...i})
      setObject(i.object)
      setResume(i.resume)
      setConclusion(i.conclusion)
      setOperations(i.operations)
    })
  }
  function cancel() {
    api.cancelIntervention(id).then(e => getIntervention())
  }
  function compareRsk(r1, r2) {
    if (r1.question > r2.question) {
      return 1
    }
    if (r1.question < r2.question) {
      return -1
    } else {
      return 0
    }
  }
  /*function handleStatus() {
    api.setInterventionStatus(id, state.status === constants.STATUS_DONE ? constants.STATUS_WRITING : constants.STATUS_DONE)
      .then(i => setState({...state, ...i}))
      .then(r => enqueueSnackbar(text.CHANGED_STATUS, {variant: "success"}))
      .catch(e => enqueueSnackbar('Erreur', {variant: "error"}))
  }*/



  return (
    <div className={"intervention"}>
      {state.status &&
      <Stepper className={"stepper"} activeStep={status.indexOf(state.status)} alternativeLabel>
        {status.map((st) => (
          <Step key={st}>
            <StepLabel>{text.STATUS_TRADUCTION[st]}</StepLabel>
          </Step>
        ))}
      </Stepper>
      }
      <div className={"margin"}>
        <img alt={""} className={"illus"} src={Icon}/>
        <div>
            <IconButton onClick={() => history.push(`/devis/${state.devis.id}`)}><img alt={"return to order"}
                                                                                    src={BonCommande}/></IconButton>

            <IconButton disabled><img alt={"create intervention Icon"} src={IntervIcon}/></IconButton>

          {state && <IconButton onClick={() => setOpenTeamInteraction(true)}><img alt={"client interraction"}
                                                                                  src={InteractionIcon}/></IconButton>}

          {/*<IconButton onClick={() => setPdfPoup(true)}><img alt={"generate a pdf"} src={GenPdfIcon}/></IconButton>*/}
          {state &&
          <IconButton disabled={!api.hasRole(constants.PERMISSION_PM)} onClick={cancel}><img alt={"cancel intervention"}
                                                                                             src={CancelIcon}/></IconButton>}

          <Modal open={openTeamInteraction} onClose={() => setOpenTeamInteraction(false)}>
            <InteractionClient id={id}/>
          </Modal>
          <Modal open={pdfPopup} onClose={() => setPdfPoup(false)}>
            <PdfBean id={id} onUpload={() => {
              getIntervention();
              setPdfPoup(false)
            }}/>
          </Modal>
          <Modal open={openSupply} onClose={() => setOpenSupply(false)}>
            <EditSupply id={id}/>
          </Modal>
        </div>


      </div>

      <div className={"generale"}>
        <h1 className={"title"}><img src={RoofIcon} className={"icon"}/>Infos</h1>
        <table>
          <thead>
          <tr>
            <th>{text.ID}</th>
            <th>{text.NUMBER}</th>
            <th>{text.CLIENT}</th>
            <th>{text.SITE}</th>
            <th>{text.STATUS}</th>
            <th>{text.DESCRIPTION}</th>
            <th>{text.INTERVENTION_START}</th>
            <th>{text.INTERVENTION_END}</th>
            <th>{text.ADDRESS}</th>
            <th>{text.PHONE_ONSITE}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{id}</td>
            <td>{state.devis && <a
              href={`/devis/${state.devis.id}`}> {state.devis.clientIdBCommande ? state.devis.clientIdBCommande : text.TO_DEFINE} </a>}</td>
            <td>{state.devis && <a href={"/client/" + state.devis?.client?.id}>{state.devis?.client?.name}</a>}</td>
            <td>{state.devis &&
            <a href={"/site/" + state.devis.site.id}>{state.devis.site.name ? state.devis.site.name : "-"}</a>}</td>
            <td>{state.status && text.STATUS_TRADUCTION[state.status]}</td>
            <td>{state.type && state.type.name}</td>
            <td>{state.dateIntervention > 0 ? new Date(state.dateIntervention).toLocaleDateString() + " " + new Date(state.dateIntervention).toLocaleTimeString() : "-"}</td>
            <td>{state.endIntervention > 0 ? new Date(state.endIntervention).toLocaleDateString() + " " + new Date(state.endIntervention).toLocaleTimeString() : "-"}</td>
            <td>{state.devis && state.devis.site.address + " " + state.devis.site.cp + " " + state.devis.site.ville}</td>
            <td>{state.devis && state.devis.site?.contactPhone[0]}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className={"client"}>
        <h1 className={"title"}><img src={InfoIcon} className={"icon"}/>{text.H1_REPORTED_INFO}</h1>

        <table>
          <thead>
          <tr>
            <th>{text.TD_NAME}</th>
            <th>{text.TD_FUNCTION}</th>
            <th>{text.MAIL}</th>
            <th>{text.TD_COMMENT}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td title={text.TD_NAME}><p>{state.feedBack && JSON.parse(state.feedBack.comment).signataire}</p></td>
            <td title={text.TD_FUNCTION}><p>{state.feedBack && JSON.parse(state.feedBack.comment).function}</p></td>
            <td title={text.MAIL}><p>{state?.feedBack?.mail}</p></td>
            <td title={text.TD_COMMENT}><IconButton onClick={() => setModal(true)}><img
              src={FuiteIcon}/></IconButton> {state.feedBack && JSON.parse(state.feedBack.comment).comment}</td>
            <Modal open={modal} onClose={() => setModal(false)}>
              {state.devis &&
              <Layer background={state.devis.site.imageUrl} points={state.feedBack ? state.feedBack.points : []}/>}
            </Modal>
          </tr>
          </tbody>
        </table>
      </div>

      {state.devis &&
      <div className={"plan"}>
        <div>
          <h2 className={"title"}><img src={PlanIcon} className={"icon"}/>{text.PLAN}</h2>
          {state.devis.site.imageUrl ? <CustomImage name={state.devis.site.imageUrl}/> :
            <div><img src={ImageManquante}/></div>}
        </div>
      </div>
      }

      <div className={"rapport"}>

        <h1 className={"title"}><img src={CaseIcon}/> {text.REPORT}  </h1>
        {
          operations?.length > 0 ?
            <Fragment>
              <WorkForceDisplayer operations={operations} trigger={text.WORK_FORCE}/>
              <table>
                <thead>
                <tr>
                  <th>{text.TD_NAME}</th>
                  <th>{text.TH_CONTRIBUTOR}</th>
                  {state.generatedReport.length >0 &&
                    <th>{text.TH_ADMINISTRATOR}</th>}
                  <th>{text.TH_IMAGES}</th>
                </tr>
                </thead>
                <tbody>
                {operations.sort((o1, o2) => o1.defaultPosition > o2.defaultPosition ? 1 : -1)
                  .map((o) => <Operation key={o.id}
                                         isReported={state.generatedReport.length > 0}
                                         interventionId={state.id}
                                         devisId={state.devis.id}
                                         image={state.devis.site.imageUrl}
                                         operation={o}
                                         pictures={o.pictures}
                                         visible={o.visible}
                                         depot={isSpecialIntervention(state.type.id)}
                                         otherOperations={operations.filter(o1 => o.id !== o1.id).map(o1 => ({
                                           id: o1.id,
                                           typeName: o1.typeName
                                         }))}/>
                  )
                }
                </tbody>
              </table>

            </Fragment> :
            <div>{text.NO_OPERATION_TRANSMITTED}</div>
        }
      </div>
      <div className={"conclusion"}>
        <h2>{text.TH_CONCLUSION}</h2>
        <textarea disabled={true} value={conclusion ? conclusion : ""}/>
      </div>

      <div className={"risk-analysis"}>
        <h1><img src={RiskIcon}/> {text.H1_RISK_ANALYSIS} </h1>

        <div>
          {state.riskAnalysis && state.riskAnalysis.length > 0 ?
            state.riskAnalysis.sort((r1, r2) => compareRsk(r1, r2)).map((risk) => <Risk {...risk} />)
            : <div>{text.NORISK_ANALYSIS}</div>
          }
        </div>

      </div>
      <div className={"documents"}>
        <h2><img src={DocumentIcon}/> {text.H2_DOCUMENTS}</h2>
        {<DocumentLoaderList name={state.generatedReport}/>}
      </div>

      <div className={"signature"}>
        <h2><img src={SignatureIcon}/>{text.SIGNATURE}</h2>
        {state.feedBack && state.feedBack.signature ?
          <CustomImage name={state.feedBack.signature} height={230} width={300}/> :
          <img alt={"signature"} src={SignatureIllust}/>}
      </div>
      <div className={"object"}>
        <h2>{text.OBJECT}</h2>
        <input disabled={true} type={"text"} value={object ? object : ""}/>
      </div>
      <div className={"resume"}>
        <h2>{text.RESUME}</h2>
        <textarea disabled={true} value={resume ? resume : ""}/>
      </div>

      {/*<div className={"status"}>
        {state.status && <IconButton disabled={!api.hasRole(constants.PERMISSION_AM)} checked={state.status.id >= 9}
                                     onClick={handleStatus}><img alt={"desk-status"}
                                                                 src={state.status === constants.STATUS_WRITING ? ValidateIcon : FinishIcon}/></IconButton>}
      </div>*/}

    </div>
  )

}
